<template>
    <div style="position: relative">
      <h1 class="text-xs-center">Agent Profile</h1>
      <br />
      <div class="px-2" style="position: absolute; top: 25px; left: 0; width: 100%;"><v-progress-linear indeterminate :active="apiInProgress" height="4px" /></div>
      <v-form v-if="currentAgent && !currentAgentError" @submit.prevent="save()" ref="form" class="mx-2" enctype="multipart/form-data">
        <div class="layout justify-end">
          <router-link :to="{ name: 'agents' }"><v-btn color="warning mr-2" flat :disabled="apiInProgress">Cancel</v-btn></router-link>
          <v-btn color="primary" type="submit" :disabled="apiInProgress || !isDataEdited || !isEditable" :loading="apiInProgress">Save</v-btn>
          <v-btn v-show="isAdmin && !currentAgent.dateArchivedLabel && currentAgent.id" color="warning" @click="onArchive" :disabled="apiInProgress || isDataEdited" :loading="apiInProgress">Archive</v-btn>
        </div>
        <v-container fluid grid-list-md style="max-width: 1000px">
          <v-layout row wrap>
            <v-flex mb-5 md8>
              <v-layout row wrap mb-1 v-if="currentAgent.dateArchivedLabel">
                <v-flex class="text-xs-right font-weight-bold" sm4 pr-3>
                  <span>Date archived</span>
                </v-flex>
                <v-flex sm8>
                  <v-chip>{{currentAgent.dateArchivedLabel}}</v-chip>
                  <div v-show="isAdmin"><v-btn small color="warning" @click="onUndoArchive">Un-archive</v-btn></div>
                </v-flex>
              </v-layout>
              <v-layout row wrap mb-1 v-if="currentAgent.verificationTokenExpiryLabel">
                <v-flex class="text-xs-right font-weight-bold" sm4 pr-3>
                  <span>Invitation expiry</span>
                </v-flex>
                <v-flex sm8>
                  {{currentAgent.verificationTokenExpiryLabel}} <span style="color: red" v-show="currentAgent.isExpired">Expired</span>
                  <div><v-btn small :disabled="isDataEdited || !isEditable" @click="onResendInvitation">Re-send invitation</v-btn></div>
                </v-flex>
              </v-layout>
              <v-layout row wrap mb-1 v-if="isEditable && currentAgent.balanceLabel">
                <v-flex class="text-xs-right font-weight-bold" sm4 pr-3>
                  <span>Current balance</span>
                </v-flex>
                <v-flex sm8>
                  {{currentAgent.balanceLabel}}
                </v-flex>
              </v-layout>
              <v-layout row wrap mb-1 v-if="currentAgent.dateCreatedLabel">
                <v-flex class="text-xs-right font-weight-bold" sm4 pr-3>
                  <span>Created</span>
                </v-flex>
                <v-flex sm8>
                  {{currentAgent.dateCreatedLabel}}
                </v-flex>
              </v-layout>
              <v-layout row wrap mb-1 v-if="currentAgent.dateUpdatedLabel">
                <v-flex class="text-xs-right font-weight-bold" sm4 pr-3>
                  <span>Updated</span>
                </v-flex>
                <v-flex sm8>
                  {{currentAgent.dateUpdatedLabel}}
                </v-flex>
              </v-layout>
              <v-layout row wrap mb-1 v-if="currentAgent.userTypeLabel">
                <v-flex class="text-xs-right font-weight-bold" sm4 pr-3>
                  <span>User role(s)</span>
                </v-flex>
                <v-flex sm8>
                  {{currentAgent.userTypeLabel}}
                </v-flex>
              </v-layout>
              <v-layout row wrap mb-1 v-if="isAdmin">
                <v-flex class="text-xs-right font-weight-bold" sm4 pr-3>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon small v-on="on">fa fa-info-circle</v-icon>
                    </template>
                    <span>Agent staff have extra privileges compared to regular agents, such as one time ticket payments</span>
                  </v-tooltip> &nbsp;
                  <span>Agent Staff role</span>
                </v-flex>
                <v-flex sm8>
                  <v-btn small :loading="toggleInProgress" :disabled="apiInProgress || toggleInProgress" @click="onToggleAgentStaffRole">{{currentAgent.isAgentStaff ? 'Remove Agent Staff role' : 'Convert to Agent Staff'}}</v-btn>
                </v-flex>
              </v-layout>
              <v-layout row wrap mb-1 v-if="!currentAgent.id">
                <v-flex class="text-xs-right font-weight-bold" mt-3 sm4 pr-3>
                  <span>Register with this user</span>
                </v-flex>
                <v-flex sm8>
                  <user-selector v-model="selectedUser" :child-props="{ clearable: true, placeholder: 'Search by email, phone, name' }" />
                </v-flex>
              </v-layout>
              <v-layout row wrap mb-1>
                <v-flex class="text-xs-right font-weight-bold" mt-3 sm4 pr-3>
                  <span>First name</span>
                </v-flex>
                <v-flex sm8>
                  <v-text-field
                    required
                    v-show="!selectedUser"
                    :readonly="!isEditable"
                    :rules="[v => v && v.trim() ? true : 'Please enter a value']"
                    placeholder="Enter first name"
                    v-model="currentAgent.firstName"
                  />
                  <v-text-field
                    readonly
                    v-if="selectedUser"
                    :value="selectedUser.firstName"
                  />
                </v-flex>
              </v-layout>
              <v-layout row wrap mb-1>
                <v-flex class="text-xs-right font-weight-bold" mt-3 sm4 pr-3>
                  <span>Last name</span>
                </v-flex>
                <v-flex sm8>
                  <v-text-field
                    required
                    v-show="!selectedUser"
                    :readonly="!isEditable"
                    :rules="[v => v && v.trim() ? true : 'Please enter a value']"
                    placeholder="Enter last name"
                    v-model="currentAgent.lastName"
                  />
                  <v-text-field
                    readonly
                    v-if="selectedUser"
                    :value="selectedUser.lastName"
                  />
                </v-flex>
              </v-layout>
              <v-layout row wrap mb-1>
                <v-flex class="text-xs-right font-weight-bold" mt-3 sm4 pr-3>
                  <span>Email</span>
                </v-flex>
                <v-flex sm8>
                  <v-text-field
                    type="email"
                    v-show="!selectedUser || !selectedUser.email"
                    :readonly="!isEditable"
                    placeholder="Enter email"
                    v-model="currentAgent.email"
                  />
                  <v-text-field
                    required
                    readonly
                    v-if="selectedUser && selectedUser.email"
                    :value="selectedUser.email"
                  />
                </v-flex>
              </v-layout>
              <v-layout row wrap mb-1>
                <v-flex class="text-xs-right font-weight-bold" mt-3 sm4 pr-3>
                  <span>Mobile number</span>
                </v-flex>
                <v-flex sm8>
                  <div v-if="currentAgent.phoneIsDeleted">
                    <br />
                    {{currentAgent.phoneNumber}}
                  </div>
                  <div v-if="!currentAgent.phoneIsDeleted">
                    <div v-show="!selectedUser || !selectedUser.phone">
                      <phone-number-field required :child-props="{ readonly: !isEditable }"
                        v-model="currentAgent.phoneNumber"
                      />
                    </div>
                    <div v-if="selectedUser && selectedUser.phone">
                      <phone-number-field :child-props="{ readonly: true }" v-model="selectedUser.phone" />
                    </div>
                  </div>
                </v-flex>
              </v-layout>
              <v-layout row wrap mb-1>
                <v-flex class="text-xs-right font-weight-bold" mt-3 sm4 pr-3>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon small v-on="on">fa fa-info-circle</v-icon>
                    </template>
                    <span>Important to allow the agent to recover their account if needed due to missing password or mobile number change</span>
                  </v-tooltip> &nbsp;
                  <span>Recovery contact</span>
                </v-flex>
                <v-flex sm8>
                  <v-text-field
                      type="email"
                      :readonly="!isEditable"
                      placeholder="Enter email"
                      v-model="currentAgent.recoveryContact"
                  />
                </v-flex>
              </v-layout>
              <v-layout row wrap mb-1>
                <v-flex class="text-xs-right font-weight-bold" mt-3 sm4 pr-3>
                  <span>Country</span>
                </v-flex>
                <v-flex sm8>
                  <v-select
                    outline
                    required
                    dense
                    :disabled="!isEditable"
                    :items="[{ text: 'Ghana', value: 'GHA' }]"
                    label="Select country"
                    @change="onChangeCountryId"
                    :value="currentAgent.countryId"
                  />
                </v-flex>
              </v-layout>
              <v-layout row wrap mb-1>
                <v-flex class="text-xs-right font-weight-bold" mt-3 sm4 pr-3>
                  <span>Timezone</span>
                </v-flex>
                <v-flex sm8>
                  <timezone-selector
                    required
                    :child-props="{ outline: true, disabled: !isEditable, label: 'Enter keywords to filter' }"
                    v-model="currentAgent.timezone"
                  />
                </v-flex>
              </v-layout>
              <v-layout row wrap mb-1>
                <v-flex class="text-xs-right font-weight-bold" mt-3 sm4 pr-3>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon small v-on="on">fa fa-info-circle</v-icon>
                    </template>
                    <span>Only enter to override the server default.<br />
                    Conversion rates are what is used to calculate the price at which an agent can provide SmartTransit passenger credit to passengers.<br />
                    This is usually higher than the one-to-one ratio of credit purchased directly by passengers.<br />
                    This allows agents to sell passenger credit at a higher value to passengers (keeping the profit).
                    </span>
                  </v-tooltip> &nbsp;
                  <span>Custom conversion rate</span>
                </v-flex>
                <v-flex sm8>
                  <v-text-field
                    suffix="%"
                    :readonly="!isEditable"
                    :persistent-hint="Boolean(defaultAgentConversionRate)"
                    :hint="computedCurrency ? `Agent exchange rate will be ${computedCurrency}1 = ${computedPassengerCredits} passenger credits (${computedProfit} agent profit)` : ''"
                    type="number"
                    :placeholder="`Enter if different from default (${defaultAgentConversionRate}%)`"
                    v-model="currentAgent.customConversionRatePercent"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex mb-5 md4>
              <div class="mb-3">
                <v-menu
                  v-model="idDocumentExpiryDateMenu"
                  close-on-content-click
                  full-width
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      prepend-icon="fal fa-calendar"
                      :value="computedDocumentExpiryDateFormatted"
                      @click:clear="currentAgent.idDocumentExpiryDate = null"
                      clearable
                      label="ID Document expiry"
                      readonly
                      v-on="isEditable ? on : null"
                    />
                  </template>
                  <v-date-picker
                    no-title
                    :min="computedDocumentExpiryDateMin"
                    v-model="currentAgent.idDocumentExpiryDate"
                    @change="idDocumentExpiryDateMenu = false"
                  />
                </v-menu>
              </div>
              <div class="mb-5">
                <div class="text-xs-center"><strong>ID Document (front)</strong></div>
                <st-uploader
                  name="idDocumentFront"
                  :editable="isEditable"
                  mime-types-group="images"
                  upload-label="Select Image/PDF"
                  v-model="frontIdDocuments"
                />
              </div>
              <div>
                <div class="text-xs-center"><strong>ID Document (back)</strong></div>
                <st-uploader
                  name="idDocumentBack"
                  :editable="isEditable"
                  mime-types-group="images"
                  upload-label="Select Image/PDF"
                  v-model="backIdDocuments"
                />
              </div>
            </v-flex>
          </v-layout>
        </v-container>
        <div class="layout justify-end">
          <router-link :to="{ name: 'agents' }"><v-btn color="warning mr-2" flat :disabled="apiInProgress">Cancel</v-btn></router-link>
          <v-btn color="primary" type="submit" :disabled="apiInProgress || !isDataEdited || !isEditable" :loading="apiInProgress">Save</v-btn>
          <v-btn v-show="isAdmin && !currentAgent.dateArchivedLabel && currentAgent.id" color="warning" @click="onArchive" :disabled="apiInProgress || isDataEdited" :loading="apiInProgress">Archive</v-btn>
        </div>
      </v-form>
      <v-alert :value="currentAgentError" type="error">
        <p>{{currentAgentError}}</p>
        <p><a href @click.stop="refreshView()">Retry</a> or <router-link :to="{ name: 'agents' }">Return to agents list</router-link></p>
      </v-alert>
    </div>
</template>

<script>
import src from './src'
export default src
</script>
