import _upperFirst from 'lodash.upperfirst'
import _cloneDeep from 'lodash.clonedeep'
import _isEqual from 'lodash.isequal'
import moment from 'moment-timezone'
import { formatCurrency, formatDate, formatTime, convertToPassengerCredits } from '@smarttransit/common'
import { extractBaseNameFromFilePath } from '@smarttransit/common-client'
import { addAlert, isAuthorized } from '../../../../utilities/helpers'

import {
  archiveAgent,
  getAgent, getAgentSecureUrl,
  getAgentTemplate,
  registerAgent,
  registerAgentWithExistingUser,
  resendAgentInvite, toggleAgentStaff,
  unArchiveAgent,
  updateAgent
} from '../../../../services/agents-service'

import { getCachedAgentConversionRatePercent } from '../../../../services/st-settings-service'

let _cachedComponentData = null

export default {
  name: 'agents-agent',
  props: {
    signedInUser: Object
  },
  data () {
    return {
      apiInProgress: false,
      toggleInProgress: false,
      currentAgent: null,
      currentAgentOriginal: null,
      currentAgentError: null,
      currentCountryCurrency: null,
      defaultAgentConversionRate: '100',
      localTimezone: moment.tz.guess(),
      timezones: [],
      frontIdDocuments: { documentsToUpload: [], uploadedDocuments: [] },
      backIdDocuments: { documentsToUpload: [], uploadedDocuments: [] },
      idDocumentExpiryDateMenu: null,
      isEditable: false,
      isAdmin: false,
      selectedUser: null
    }
  },
  computed: {
    computedDocumentExpiryDateFormatted () {
      return this.currentAgent.idDocumentExpiryDate ? formatDate(this.currentAgent.idDocumentExpiryDate) : ''
    },
    computedDocumentExpiryDateMin () {
      return moment(new Date()).add(2, 'weeks').format('Y-MM-DD')
    },
    computedCurrency () {
      return (this.currentCountryCurrency && this.currentCountryCurrency.currencySymbol) || ''
    },
    computedPassengerCredits () {
      const conversionRate = this.currentAgent?.customConversionRatePercent || this.defaultAgentConversionRate

      if (conversionRate) {
        return formatCurrency(convertToPassengerCredits({ amountAsCents: 100, conversionRatePercent: Number(conversionRate) }))
      } else {
        return ''
      }
    },
    computedProfit () {
      if (this.computedPassengerCredits) {
        let profit = Math.floor(Number(this.computedPassengerCredits) * 100) - 100
        return formatCurrency(profit)
      }
    },
    isDataEdited () {
      return !_isEqual(this.currentAgent, this.currentAgentOriginal)
    }
  },
  watch: {
    selectedUser (newVal) {
      if (newVal) {
        this.currentAgent = { ...this.currentAgent, stUserId: newVal.id }
        // this.currentAgent.stUserId = newVal.id
      } else if (this.currentAgent.stUserId) {
        delete this.currentAgent.stUserId
      }
    },
    frontIdDocuments (documents) {
      this.currentAgent.idDocumentFrontFile = null

      if (documents.documentsToUpload) {
        documents.documentsToUpload.forEach((newFile) => {
          if (!newFile.error) {
            this.currentAgent = {
              ...this.currentAgent,
              idDocumentFrontFile: newFile.file,
              idDocumentFrontFilename: newFile.name
            }
          }
        })
      }
    },
    backIdDocuments (documents) {
      this.currentAgent.idDocumentBackFile = null

      if (documents.documentsToUpload) {
        documents.documentsToUpload.forEach((newFile) => {
          if (!newFile.error) {
            this.currentAgent = {
              ...this.currentAgent,
              idDocumentBackFile: newFile.file,
              idDocumentBackFilename: newFile.name
            }
          }
        })
      }
    }
  },
  mounted () {
    async function onRouterLoad () {
      if (this.$route.params.agentId + '' === '0') {
        if (this.isAdmin) {
          this.currentAgent = getAgentTemplate()
          this.currentAgent.stUser.timezone = this.localTimezone
          this.setCurrentAgent()
          this.setDefaultAgentConversionRate()
        } else {
          this.currentAgentError = 'The current user does not have permission to create an agent'
        }
      } else {
        this.loadAgent(this.$route.params.agentId)
      }
    }

    this.isAdmin = isAuthorized(this.$props.signedInUser, 'admin')
    this.isEditable = this.isAdmin
    this.$nextTick(onRouterLoad.bind(this))
  },
  methods: {
    validForm () {
      return this.$refs &&
        this.$refs.form &&
        (this.signedInUser || this.$refs.form.validate()) &&
        (this.signedInUser || this.currentAgent.email || this.currentAgent.phoneNumber)
    },
    clearFrontIdUpload () {
      this.currentAgent.idDocumentFrontFile = null

      this.frontIdDocuments = {
        ...this.frontIdDocuments,
        documentsToUpload: []
      }
    },
    clearBackIdUpload () {
      this.currentAgent.idDocumentBackFile = null

      this.backIdDocuments = {
        ...this.backIdDocuments,
        documentsToUpload: []
      }
    },
    onChangeCountryId (val) {
      this.currentAgent.countryId = val
      this.setDefaultAgentConversionRate()
    },
    setDefaultAgentConversionRate () {
      getCachedAgentConversionRatePercent({ countryId: this.currentAgent.countryId })
        .then((result) => {
          this.defaultAgentConversionRate = result?.conversionRatePercent
        }).catch((err) => {
          addAlert({ message: err, type: 'error' })
        })
    },
    refreshView () {
      this.$router.go()
    },
    async loadAgent (id) {
      try {
        this.currentAgent = await getAgent({ id, includeArchived: true })
        await this.setCurrentAgent()
        this.setDefaultAgentConversionRate()
      } catch (error) {
        addAlert({ message: error, type: 'error' })
      }
    },
    save () {
      if (!this.signedInUser && !this.currentAgent.phoneNumber) {
        return alert('Please provide a mobile number')
      }

      if (this.validForm()) {
        if (this.currentAgent.id) {
          this.updateAgent()
        } else {
          if (!this.currentAgent.idDocumentFrontFile || !this.currentAgent.idDocumentBackFile) {
            return alert('Please provide ID Documents (front and back)')
          }

          this.createAgent()
        }
      }
    },
    async updateAgent () {
      try {
        this.apiInProgress = true
        await updateAgent({ ...this.currentAgent })
        this.clearFrontIdUpload()
        this.clearBackIdUpload()
        await this.loadAgent(this.currentAgent.id)
        addAlert({ message: 'Successfully updated agent', type: 'success' })
      } catch (error) {
        addAlert({ message: error, type: 'error' })
      } finally {
        this.apiInProgress = false
      }
    },
    async createAgent () {
      try {
        this.apiInProgress = true
        let currentAgent

        if (this.currentAgent.stUserId) {
          currentAgent = await registerAgentWithExistingUser({ ...this.currentAgent })
        } else {
          currentAgent = await registerAgent({ ...this.currentAgent })
        }

        this.clearFrontIdUpload()
        this.clearBackIdUpload()
        await this.loadAgent(currentAgent.id)
        addAlert({ message: 'Successfully registered agent', type: 'success' })
        this.$router.push({ name: 'agents' })
      } catch (error) {
        addAlert({ message: error, type: 'error' })
      } finally {
        this.apiInProgress = false
      }
    },
    async onArchive () {
      if (this.isAdmin) {
        const code = this.currentAgent.phoneNumber.substring(this.currentAgent.phoneNumber.length - 4)
        const promptResult = prompt(`Enter "${code}" below to confirm ARCHIVING this agent`)

        if (promptResult) {
          if (promptResult !== code) {
            alert('Please check the code entered and try again')
          } else {
            try {
              this.apiInProgress = true
              await archiveAgent(this.currentAgent.id)
              await this.loadAgent(this.currentAgent.id)

              addAlert({
                message: `Successfully archived agent ${this.currentAgent.firstName} ${this.currentAgent.lastName}`,
                type: 'success'
              })
            } catch (err) {
              addAlert({ message: err, type: 'error' })
            } finally {
              this.apiInProgress = false
            }
          }
        }
      }
    },
    async onUndoArchive () {
      if (this.isAdmin) {
        const code = this.currentAgent.phoneNumber.substring(this.currentAgent.phoneNumber.length - 4)
        const promptResult = prompt(`Enter "${code}" below to confirm un-archiving this agent`)

        if (promptResult) {
          if (promptResult !== code) {
            alert('Please check the code entered and try again')
          } else {
            try {
              this.apiInProgress = true
              await unArchiveAgent(this.currentAgent.id)
              await this.loadAgent(this.currentAgent.id)

              addAlert({
                message: `Successfully un-archived agent ${this.currentAgent.firstName} ${this.currentAgent.lastName}`,
                type: 'success'
              })
            } catch (error) {
              addAlert({ message: error, type: 'error', isModal: true })
            } finally {
              this.apiInProgress = false
            }
          }
        }
      }
    },
    async onResendInvitation () {
      if (this.isEditable && confirm(`Confirm re-sending invitation for ${this.currentAgent.stUser.firstName} ${this.currentAgent.stUser.lastName}`)) {
        try {
          this.apiInProgress = true
          await resendAgentInvite(this.currentAgent.id)
          await this.loadAgent(this.currentAgent.id)
          addAlert({ message: `Successfully re-sent invitation for ${this.currentAgent.stUser.firstName} ${this.currentAgent.stUser.lastName}`, type: 'success' })
        } catch (err) {
          addAlert({ message: err, type: 'error', isModal: true })
        } finally {
          this.apiInProgress = false
        }
      }
    },
    async onToggleAgentStaffRole () {
      if (this.isEditable && confirm(`Confirm switching this profile to "${this.currentAgent.isAgentStaff ? 'Agent' : 'Agent Staff'}" role`)) {
        try {
          this.toggleInProgress = true
          await toggleAgentStaff({ id: this.currentAgent.id, asAgentStaff: !this.currentAgent.isAgentStaff })
        } catch (err) {
          addAlert({ message: err, type: 'error', isModal: true })
        } finally {
          this.toggleInProgress = false
        }

        await this.loadAgent(this.currentAgent.id)
      }
    },
    async setCurrentAgent () {
      if (_cachedComponentData) {
        this.currentAgent = _cachedComponentData
      } else {
        if (this.currentAgent.countryCurrency) {
          this.currentCountryCurrency = this.currentAgent.countryCurrency
        } else {
          const currencyResults = await this.$store.getters.getCountryCurrencies
          this.currentCountryCurrency = currencyResults[this.currentAgent.countryId]
        }

        this.currentAgent.firstName = this.currentAgent.stUser.firstName
        this.currentAgent.lastName = this.currentAgent.stUser.lastName
        this.currentAgent.email = this.currentAgent.stUser.email
        this.currentAgent.phoneNumber = this.currentAgent.stUser.phone
        this.currentAgent.balanceLabel = this.currentAgent.id ? (this.currentCountryCurrency ? this.currentCountryCurrency.currencySymbol : '') + formatCurrency(this.currentAgent.balance) : ''
        this.currentAgent.isExpired = new Date(this.currentAgent.stUser.verificationTokenExpiry).getTime() <= Date.now()
        this.currentAgent.verificationTokenExpiryLabel = this.currentAgent.stUser.verificationToken && this.currentAgent.stUser.verificationType && this.currentAgent.stUser.verificationTokenExpiry ? `${formatTime(this.currentAgent.stUser.verificationTokenExpiry)}, ${formatDate(this.currentAgent.stUser.verificationTokenExpiry)}` : null
        this.currentAgent.dateArchivedLabel = this.currentAgent.dateArchived ? `${formatTime(this.currentAgent.dateArchived)}, ${formatDate(this.currentAgent.dateArchived)}` : null
        this.currentAgent.dateCreatedLabel = this.currentAgent.dateCreated ? `${formatTime(this.currentAgent.dateCreated)}, ${formatDate(this.currentAgent.dateCreated)}` : ''
        this.currentAgent.dateUpdatedLabel = this.currentAgent.dateUpdated ? `${formatTime(this.currentAgent.dateUpdated)}, ${formatDate(this.currentAgent.dateUpdated)}` : ''
        this.currentAgent.userType = this.currentAgent.stUser.roles && this.currentAgent.stUser.roles.length ? this.currentAgent.stUser.roles[0].name : null
        this.currentAgent.userTypeLabel = this.currentAgent.stUser.roles ? this.currentAgent.stUser.roles.map(o => _upperFirst(o.name === 'investor' ? 'stakeholder' : o.name)).join(', ') : ''
        this.currentAgent.isAgentStaff = this.currentAgent.stUser.roles && this.currentAgent.stUser.roles.some(o => o.name === 'agentstaff')
        this.currentAgent.timezone = this.currentAgent.stUser.timezone || ''
        this.currentAgent.phoneIsDeleted = this.currentAgent.phoneNumber && this.currentAgent.phoneNumber.indexOf('deleted') > -1

        if (this.currentAgent.agentProfileDocuments && this.currentAgent.agentProfileDocuments.length) {
          const promises = []
          const idDocumentFront = this.currentAgent.agentProfileDocuments.find((o) => (o.metadata.type === 'idDocumentFront'))
          const idDocumentBack = this.currentAgent.agentProfileDocuments.find((o) => (o.metadata.type === 'idDocumentBack'))

          if (idDocumentFront) {
            this.frontIdDocuments = {
              ...this.frontIdDocuments,
              uploadedDocuments: [
                { filename: extractBaseNameFromFilePath(idDocumentFront.documentSrc), type: idDocumentFront.metadata.mimetype, size: idDocumentFront.metadata.size }
              ]
            }

            promises.push(getAgentSecureUrl({ documentId: idDocumentFront.id }))
          }

          if (idDocumentBack) {
            this.backIdDocuments = {
              ...this.backIdDocuments,
              uploadedDocuments: [
                { filename: extractBaseNameFromFilePath(idDocumentBack.documentSrc), type: idDocumentBack.metadata.mimetype, size: idDocumentBack.metadata.size }
              ]
            }

            promises.push(getAgentSecureUrl({ documentId: idDocumentBack.id }))
          }

          let secureUrlResults

          if (promises.length) {
            secureUrlResults = await Promise.all(promises)
          }

          if (idDocumentFront) {
            this.frontIdDocuments.uploadedDocuments[0].url = secureUrlResults[0]

            this.frontIdDocuments = {
              ...this.frontIdDocuments,
              uploadedDocuments: [...this.frontIdDocuments.uploadedDocuments]
            }

            this.currentAgent.idDocumentExpiryDate = idDocumentFront.dateExpires
          }

          if (idDocumentBack) {
            this.backIdDocuments.uploadedDocuments[0].url = secureUrlResults[1]

            this.backIdDocuments = {
              ...this.backIdDocuments,
              uploadedDocuments: [...this.backIdDocuments.uploadedDocuments]
            }
          }
        }

        this.currentAgent.recoveryContact = (this.currentAgent.stUser && this.currentAgent.stUser.metadata && this.currentAgent.stUser.metadata.recoveryContact) || ''
        // this.currentAgent.totalRegisteredPassengers = (this.currentAgent.agentRegisteredPassengers && this.currentAgent.agentRegisteredPassengers.length) || '0'
        this.isEditable = !this.currentAgent.dateArchivedLabel && (this.$props.signedInUser.id === this.currentAgent.stUser.id || this.isAdmin)
      }

      this.currentAgent = { ...this.currentAgent }
      this.currentAgentOriginal = _cloneDeep(this.currentAgent)
    }
  },
  beforeRouteLeave (to, from, next) {
    if (!this.isDataEdited || confirm('Discard changes made?')) {
      _cachedComponentData = null
      next()
    } else {
      _cachedComponentData = this.currentAgent
      next(false)
    }
  }
}
